import raw from "./words.txt";

import "./App.css";
import React, { useMemo } from "react";
import Button from "./Button";

function App() {
  const [result, setResult] = React.useState([]);
  const [searchTxt, setSearchTxt] = React.useState("");
  const [wordIndex, setWordIndex] = React.useState(0);
  const [searchType, setSearchType] = React.useState("start");
  const [dirty, setDirty] = React.useState(false);
  const dict = useMemo(() => [], []);

  const matchCondition = React.useCallback(
    (index) => {
      const result = dict.filter((word) => {
        const words = word.split(" ");
        return (
          words.length === 2 &&
          words[index].toLowerCase() === searchTxt.toLowerCase()
        );
      });
      return result;
    },
    [dict, searchTxt]
  );

  const resetWordIndex = React.useCallback(
    () => setWordIndex(Math.floor(Math.random() * result.length)),
    [result]
  );

  React.useEffect(() => {
    if (searchTxt === "") {
      setResult([]);
      setDirty(false);
      return;
    }
  }, [searchTxt]);

  const handleStartButtonClick = React.useCallback(() => {
    setSearchType("start");
    resetWordIndex();
    setResult(matchCondition(0));
    setDirty(true);
  }, [matchCondition, resetWordIndex]);

  const handleEndButtonClick = React.useCallback(() => {
    setSearchType("end");
    resetWordIndex();
    setResult(matchCondition(1));
    setDirty(true);
  }, [matchCondition, resetWordIndex]);

  // when user click enter, it should submit the form
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleStartButtonClick();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleStartButtonClick]);

  React.useEffect(() => {
    fetch(raw)
      .then((r) => r.text())
      .then((words) => {
        // Split the file contents into lines
        const lines = words.trim().split("\n");

        // Iterate through each line and parse the JSON
        lines.forEach((line) => {
          try {
            const word = JSON.parse(line);
            dict.push(word.text);
          } catch (error) {
            console.error("Error parsing line:", line);
          }
        });
      });
  }, [dict]);

  return (
    <div className="App">
      <div>
        <h1>Từ-nối</h1>
        <div className="container">
          <form onSubmit={handleStartButtonClick}>
            {!dirty && (
              <div className="button-container">
                <input
                  name="search"
                  type="text"
                  value={searchTxt}
                  onChange={(e) => {
                    setSearchTxt(e.target.value.trim());
                  }}
                />
                <Button
                  type="button"
                  onClick={handleStartButtonClick}
                  disabled={searchTxt === ""}
                >
                  Bắt đầu bằng từ này
                </Button>
                <Button
                  onClick={handleEndButtonClick}
                  type="button"
                  disabled={searchTxt === ""}
                >
                  Kết thúc bằng từ này
                </Button>
              </div>
            )}

            {/* Show all results */}
            {result[wordIndex] && (
              <div className="result-container">
                <h3>
                  {searchType === "start" ? `${searchTxt}-*` : `*-${searchTxt}`}
                </h3>
                <div className="result">
                  <p>{result[wordIndex]}</p>
                </div>
              </div>
            )}

            {!result[wordIndex] && dirty && (
              <div className="empty-container">Thua!</div>
            )}

            <div className="button-container">
              {result[0] && (
                <Button
                  onClick={() =>
                    setWordIndex((wordIndex) => {
                      // if word index is the last word in result, set it to 0
                      if (wordIndex === result.length - 1) {
                        return 0;
                      }
                      return wordIndex + 1;
                    })
                  }
                  type="button"
                >
                  Từ khác
                </Button>
              )}
              {dirty && (
                // button to reset the form
                <Button
                  onClick={() => {
                    setResult([]);
                    setSearchTxt("");
                    setDirty(false);
                  }}
                  type="button"
                >
                  Reset
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
      <footer>
        <p>
          Made with{" "}
          <span role="img" aria-label="love">
            ❤️
          </span>{" "}
          by{" "}
          <a
            href="https://www.instagram.com/daonaysaomay"
            target="_blank"
            rel="noopener noreferrer"
          >
            daonaysaomay
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;
